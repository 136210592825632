import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import Image from 'next/image'; // Assuming you're using Next.js for image optimization
import { useRouter } from 'next/router'; // Assuming you're using Next.js
import JobFilterSideBar from './JobFilterSideBar'; // Import the filter sidebar

const JobCards = ({ jobData }) => {
  const router = useRouter();

  // State for filters
  const [filters, setFilters] = useState({
    employmentTypes: [],
    seniorityLevels: [],
    salaryRange: [10000, 500000],
  });

  // Handle filter update from the JobFilterSideBar
  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  // Filter job data based on the selected filters
  const filteredJobs = jobData.filter((job) => {
    // Filter by employment types
    if (
      filters.employmentTypes.length &&
      !filters.employmentTypes.includes(job.type)
    ) {
      return false;
    }

    // Filter by seniority levels
    if (
      filters.seniorityLevels.length &&
      !filters.seniorityLevels.includes(job.seniority)
    ) {
      return false;
    }

    // Filter by salary range
    if (
      job.salary < filters.salaryRange[0] ||
      job.salary > filters.salaryRange[1]
    ) {
      return false;
    }

    return true;
  });

  return (
    <Container maxWidth="xl" sx={{ marginTop: '20px' }}>
      <Grid container spacing={3}>
        {/* Job Filter Sidebar (Left Side) */}
        <Grid item xs={12} sm={3} md={2} marginLeft={'-20px'}>
          <JobFilterSideBar
            filters={filters}
            onApplyFilters={handleApplyFilters}
          />
        </Grid>

        {/* Job Cards Section */}
        <Grid
          item
          xs={12}
          sm={9}
          md={9}
          style={{ marginLeft: '50px', position: 'relative', left: '90px' }}
        >
          {/* Header for the Job Cards */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '40px',
              marginBottom: '20px',
            }}
          >
            <Divider
              sx={{
                borderColor: '#1D2032', // Divider color
                flexGrow: 1,
              }}
            />
            <Typography
              sx={{
                color: '#57607A', // Text color
                paddingX: '16px',
                fontSize: { xs: '12px', sm: '14px' }, // Font size for smaller screens
                border: '1px solid #d3d3d3', // Add grey border around the text
                borderRadius: '4px', // Optional: Add rounded corners
              }}
            >
              Jobs for you
            </Typography>
            <Divider
              sx={{
                borderColor: '#1D2032', // Divider color
                flexGrow: 1,
              }}
            />
          </Box>

          {/* Check if filteredJobs is empty */}
          {filteredJobs.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
                color: '#57607A',
              }}
            >
              <Typography variant="h6">No Jobs Available :(</Typography>
            </Box>
          ) : (
            <Grid container spacing={4}>
              {filteredJobs.map((jobpost) => (
                <Grid
                  item
                  xs={12}
                  sm={6} // 2 columns on small screens
                  md={4} // 3 columns on medium and larger screens
                  key={jobpost.id}
                  onClick={() => router.push(`/job/${jobpost.id}`)}
                >
                  <Card
                    sx={{
                      borderRadius: 6,
                      boxShadow: 0,
                      maxHeight: '300px',
                      minHeight: '250px',
                      border: '1px solid transparent',
                      borderColor: '#EBEBEB',
                      transition:
                        'border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      cursor: 'pointer', // Change cursor to pointer
                      '&:hover': {
                        borderColor: '#427BF1',
                        boxShadow: '0px 4px 20px rgba(66, 123, 241, 0.2)', // Add drop shadow
                        transform: 'scale(1.03)', // Slightly increase size
                      },
                    }}
                  >
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Image
                          src={jobpost.company_icon}
                          width={40}
                          height={40}
                          alt={jobpost.company_name}
                          style={{
                            margin: '10px',
                            borderRadius: '50%',
                          }}
                        />
                        <Box>
                          <Typography
                            variant="h6"
                            component="div"
                            fontWeight="bold"
                          >
                            {jobpost.job_domain}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: '#596574' }}
                          >
                            {jobpost.company_name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ borderBottom: '1px dotted grey', mb: 2 }} />
                      <Typography variant="body2" color="text.secondary" mt={1}>
                        {jobpost.location}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#006DDD',
                          fontWeight: 'bold',
                        }}
                        mt={1}
                      >
                        {jobpost.salary_range} / month
                      </Typography>
                      <Box display="flex" justifyContent="space-between" mt={1}>
                        <Typography variant="body2" color="text.secondary">
                          {jobpost.exp_reqd} years
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {new Date(jobpost.date).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default JobCards;
