import React, { useState } from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Slider,
  Typography,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JobFilterSideBar = ({ filters, onApplyFilters }) => {
  const [employmentOpen, setEmploymentOpen] = useState(true);
  const [seniorityOpen, setSeniorityOpen] = useState(true);
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState(
    filters.employmentTypes || []
  );
  const [selectedSeniorityLevels, setSelectedSeniorityLevels] = useState(
    filters.seniorityLevels || []
  );
  const [salaryRange, setSalaryRange] = useState(
    filters.salaryRange || [10000, 500000]
  );

  const toggleEmployment = () => setEmploymentOpen(!employmentOpen);
  const toggleSeniority = () => setSeniorityOpen(!seniorityOpen);

  const handleEmploymentChange = (type) => {
    setSelectedEmploymentTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const handleSeniorityChange = (level) => {
    setSelectedSeniorityLevels((prev) =>
      prev.includes(level) ? prev.filter((l) => l !== level) : [...prev, level]
    );
  };

  const handleApply = () => {
    onApplyFilters({
      employmentTypes: selectedEmploymentTypes,
      seniorityLevels: selectedSeniorityLevels,
      salaryRange,
    });
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: '20px',
        padding: '20px',
        width: '280px',
        paddingLeft: '30px',
      }}
      sx={{
        display: { xs: 'none', sm: 'block' }, // Hide on mobile view using sx prop
      }}
    >
      {/* Type of Employment */}
      <div style={{ marginBottom: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={toggleEmployment}
        >
          <Typography variant="h6" fontWeight="bold">
            Type of Employment
          </Typography>
          <ExpandMoreIcon
            style={{
              transform: employmentOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </div>
        <Collapse in={employmentOpen}>
          {[
            'Full Time Jobs',
            'Part Time Jobs',
            'Remote Jobs',
            'Internship Jobs',
            'Training Jobs',
          ].map((label) => (
            <FormControlLabel
              key={label}
              control={
                <Checkbox
                  checked={selectedEmploymentTypes.includes(label)}
                  onChange={() => handleEmploymentChange(label)}
                />
              }
              label={label}
            />
          ))}
        </Collapse>
      </div>

      {/* Seniority Level */}
      <div style={{ marginBottom: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={toggleSeniority}
        >
          <Typography variant="h6" fontWeight="bold">
            Seniority Level
          </Typography>
          <ExpandMoreIcon
            style={{
              transform: seniorityOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </div>
        <Collapse in={seniorityOpen}>
          {[
            'Student Level',
            'Entry Level',
            'Mid Level',
            'Senior Level',
            'Directors',
            'VP or Above',
          ].map((label) => (
            <FormControlLabel
              key={label}
              control={
                <Checkbox
                  checked={selectedSeniorityLevels.includes(label)}
                  onChange={() => handleSeniorityChange(label)}
                />
              }
              label={label}
            />
          ))}
        </Collapse>
      </div>

      {/* Salary Range */}
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="h6" fontWeight="bold">
          Salary Range
        </Typography>
        <Slider
          value={salaryRange}
          onChange={(e, newValue) => setSalaryRange(newValue)}
          valueLabelDisplay="auto"
          min={10000}
          max={500000}
        />
      </div>

      {/* Action Buttons */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleApply}
          style={{ borderRadius: '10px', fontWeight: 'bold', width: '45%' }}
        >
          Apply
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#E0E0E0',
            color: '#9E9E9E',
            borderRadius: '10px',
            fontWeight: 'bold',
            width: '45%',
          }}
          onClick={() => {
            setSelectedEmploymentTypes([]);
            setSelectedSeniorityLevels([]);
            setSalaryRange([10000, 500000]);
            onApplyFilters({
              employmentTypes: [],
              seniorityLevels: [],
              salaryRange: [10000, 500000],
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default JobFilterSideBar;
