import { SignIn, SignUp, useUser } from '@clerk/nextjs'
import { useState } from 'react';
import Image from 'next/image';
import backgroundImage from '../public/assets/background/popup-bg.png';
import verifyPopup from '../public/assets/logo/verify-popup.png';
import logo from "../public/assets/logo/airedify-popup.svg";
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { dark, neobrutalism , shadesOfPurple } from '@clerk/themes'

const Popup = ({ onClose }) => {
  // const [isOpen, setIsOpen] = useState(true);

  const whatsappComUrl = "https://whatsapp.com/channel/0029VadJcMIFCCoPxFYzo83E";
  const router = useRouter();
  const handleClose = () => {
      
    router.push('/auth/apply');

  };

  const handleRedirect = () => {
    window.open(whatsappComUrl , "_blank");
  }

  // if (!isOpen) return null;



  return(

        <section   style={{height : "100%" , width : '100%' , position : "fixed" , top: "0" , left : "0" ,  zIndex : "1000" , backgroundColor : "rgba(0, 0, 0, 0.5)" , display : "flex" , justifyContent : "center" , alignItems :  "center"}}>
          <section id="sign-up">
            <SignUp appearance={{
                    variables: { colorPrimary: 'blue' },
                  }} 
                  
                  signInUrl='/auth/login' 
                  routing='hash'
            />
          </section>
        </section>
  )

  // if(!isAllowed && isAuthenticated) {
  //   return (
  //     <div style={popupStyles.overlay}>
  //       <div style={popupStyles.popup}>
  //         <div style={popupStyles.imageContainer}>
  //           <Image src={verifyPopup} alt="airedify" width={200} height={200} />
  //           <h3 style={popupStyles.title}>
  //             <span style={{ ...popupStyles.bold , font : "bold" , fontSize : "30px"}}>Your Application is Under Review</span>
  //             <br />
  //             <span style={{...popupStyles.regular , fontSize : "20px" }}>Will Contact you soon !</span>
  //           </h3>
  //         </div>
  //         <button style={{...popupStyles.button , backgroundColor : "#03AB3C" , fontSize : "25px" , fontWeight : "bold" , padding : "15px 20px 15px 20px" , borderRadius : "30px" , }} onClick={handleRedirect}>
  //           Join Whatsapp Channel
  //         </button>
  //         <p style={{marginTop:"8px"}}><span style={{fontWeight:"600"}}>Already a Member.</span> <span style={{textDecoration: 'underline', color:"#427BF1"}}
  //           onClick={() => {
  //             router.push('/auth/login')
  //           }}
  //         >SignIn</span></p>
  //       </div>
  //     </div>
  //   );
  // }


  // return (
  //   <div style={popupStyles.overlay}>
  //     <div style={popupStyles.popup}>
  //       <div style={popupStyles.imageContainer}>
  //         <Image src={logo} alt="airedify" width={200} height={200} />
  //         <h3 style={popupStyles.title}>
  //           <span style={popupStyles.regular}>Ready To Join the</span>
  //           <br />
  //           <span style={popupStyles.bold}>World’s Largest Network of Learners?</span>
  //         </h3>
  //       </div>
  //       <button style={popupStyles.button} onClick={handleClose}>
  //         Apply Here
  //       </button>
  //       <p style={{marginTop:"8px"}}><span style={{fontWeight:"600"}}>Already a Member.</span> <span style={{textDecoration: 'underline', color:"#427BF1",  cursor: 'pointer'}}
  //         onClick={() => {
  //           router.push('/auth/login')
  //         }}
  //       >SignIn</span></p>
  //     </div>
  //   </div>
  // );
};

const popupStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent background
    padding: '20px',
    borderRadius: '10px',
    backdropFilter: 'blur(5px)', // blur effect
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // shadow
    border: '1px solid grey', // grey border
    textAlign: 'center',
    maxWidth: '700px', // Increased maxWidth
    width: '90%', // Adjust width as needed
    height: 'auto', // Adjust or set a fixed height if needed
  },
  imageContainer: {
    backgroundImage: `url(${backgroundImage.src})`,
    width: '100%',
    height: '100%', // Adjust or set a specific height if needed
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: '0',
    padding: '20px', // Add padding to create space around content
    boxSizing: 'border-box',
  },
  title: {
    margin: '20px 0', // Adjust margin as needed
  },
  regular: {
    fontWeight: 'normal',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#0070f3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Popup;