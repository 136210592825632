function convertToIST(utcDateString : string) {
    // Create a Date object from the UTC string
    const date = new Date(utcDateString);
    
    // Convert to IST
    const options = { 
      timeZone: 'Asia/Kolkata',
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit',
      hour12: true
    };
    
    // @ts-ignore 
    return date.toLocaleString('en-IN', options);
  
}

export default convertToIST;  