import { Layout } from '@msanvarov/core-components';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
  Dialog,
  Slider,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Divider,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SearchIcon from '@mui/icons-material/Search';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useState } from 'react';
import getData, { getClickData } from '../utils/dataFetch';
import { useRouter } from 'next/router';
import ArticleHeaderBg from '../public/assets/background/job-header.png'; // Ensure this path is correct
import {
  setJobs,
  setUserPref,
  useAppDispatch,
  useAppSelector,
} from '@msanvarov/store';
import Image from 'next/image';
import Back from '../public/assets/icons/back.svg';

import AgricultureIcon from '@mui/icons-material/Agriculture';
// import CodeIcon from '@mui/icons-material/Code';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FactoryIcon from '@mui/icons-material/Factory';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ConstructionIcon from '@mui/icons-material/Construction';
import SchoolIcon from '@mui/icons-material/School';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import PublicIcon from '@mui/icons-material/Public';

import WorkOutlineIcon from '@mui/icons-material/WorkOutline'; // for Job Type
import CategoryIcon from '@mui/icons-material/Category'; // for Categories
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // for Salary
import InterestsIcon from '@mui/icons-material/Interests'; // for Areas of Interest
import SearchIcon from '@mui/icons-material/Search'; // for Keywords
import { useUser } from '@clerk/nextjs';
import Popup from './popup';
import DateToIST from '../utils/DateToIST';
import JobSearchBar from '../components/JobSearchBar';
import JobHeader from '../components/JobHeader';
import heroGrid from '../public/assets/background/hero-grid-job.svg';
import JobCards from '../components/JobCards';
import AppliedJob from '../components/AppliedJob';
import JobFilterSideBar from '../components/JobFilterSideBar';
import CustomLoader from '../components/loaders/CustomLoader';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const icons = {
  Agriculture: (props: any) => <AgricultureIcon {...props} />,
  SoftwareDevelopment: (props: any) => <CodeIcon {...props} />,
  'Mining&Minerals': (props: any) => <LocalGasStationIcon {...props} />,
  Utilities: (props: any) => <AccountTreeIcon {...props} />,
  Construction: (props: any) => <FactoryIcon {...props} />,
  Manufacturing: (props: any) => <FactoryIcon {...props} />,
  Wholesale_Trade: (props: any) => <AttachMoneyIcon {...props} />,
  Retail_Trade: (props: any) => <WarehouseIcon {...props} />,
  'Transportation&Warehousing': (props: any) => <AgricultureIcon {...props} />,
  Information: (props: any) => <AccountBalanceIcon {...props} />,
  'Finance&Insurance': (props: any) => <HomeWorkIcon {...props} />,
  'Real_Estate&Rental': (props: any) => <ConstructionIcon {...props} />,
  ProfessionalScientific: (props: any) => <AgricultureIcon {...props} />,
  ManagementofCompanies: (props: any) => <AgricultureIcon {...props} />,
  'Administrative&Support&Waste': (props: any) => (
    <AgricultureIcon {...props} />
  ),
  'Management&Remediation_Services': (props: any) => (
    <AgricultureIcon {...props} />
  ),
  Educational_Services: (props: any) => <AgricultureIcon {...props} />,
  'Health_Care&Social_Assistance': (props: any) => <SchoolIcon {...props} />,
  'Arts&Entertainment': (props: any) => <NotStartedIcon {...props} />,
  'Accommodation&Food_Services': (props: any) => (
    <LocalHospitalIcon {...props} />
  ),
  Other_Services: (props: any) => <FoodBankIcon {...props} />,
  Public_Administration: (props: any) => <PublicIcon {...props} />,
};

const Jobs = () => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const sheet_name = 'jobs';
  const [showPopup, setShowPopup] = useState(true);
  const dispatch = useAppDispatch();

  const { value } = useAppSelector((state) => state.auth);

  console.log(value, 'values');
  const { isSignedIn, isLoaded, user } = useUser();
  const userpref: any = useAppSelector((state) => state.userpref.userpref);

  useEffect(() => {
    if (
      !Boolean(Object.keys(value).length) &&
      !user?.emailAddresses[0].emailAddress
    ) {
      setShowPopup(true);
    }

    if (user?.emailAddresses[0].emailAddress) {
      setShowPopup(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.firstName) {
      console.log(user.primaryEmailAddress?.emailAddress);
      getClickData(user.primaryEmailAddress?.emailAddress).then((data) => {
        console.log(data);
        dispatch(setUserPref(data?.data));
      });
    }
  }, [user]);

  useEffect(() => {
    getData(sheet_name, null).then((res) => {
      setJobData(res?.data?.reverse());
      setLoading(false);
    });
  }, []);

  if (jobData) {
    dispatch(setJobs(jobData));
  }

  console.log(jobData);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isSignedIn && !user?.emailAddresses[0].emailAddress) {
    console.log(userpref, 'userpreffff....');
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Make it full-screen
          }}
        >
          <CircularProgress size={80} /> {/* Full-screen loader */}
        </Box>
      </>
    );
  }

  console.log(userpref, 'userpreffff');

  return (
    <Layout>
      <section className="blog-area">
        {loading && <CustomLoader />}

        {/* Main content section */}
        {!loading && (
          <Container
            maxWidth={false}
            sx={{ paddingLeft: 0, paddingRight: 0, margin: 0, width: '100%' }}
          >
            {!user?.emailAddresses[0]?.emailAddress && (
              <Popup onClose={() => setShowPopup(false)} />
            )}

            {/* Hero Section */}
            <Box
              sx={{
                width: '100%',
                backgroundImage: `url(${heroGrid.src})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                paddingTop: { xs: '20px', sm: '50px' },
                paddingBottom: { xs: '30px', sm: '50px' },
                marginTop: { xs: '-20px', sm: '-80px' }, // Set marginTop for mobile view
              }}
            >
              <Typography
                variant="h3"
                component="h3"
                align="center"
                gutterBottom
                sx={{
                  fontSize: { xs: '28px', sm: '45px' },
                  fontWeight: '600',
                  marginTop: '20px',
                  marginBottom: '30px',
                }}
              >
                Apply for Jobs & Internships
              </Typography>
              <Typography
                align="center"
                gutterBottom
                sx={{
                  fontSize: { xs: '12px', sm: '15px' },
                  fontWeight: '400',
                  marginTop: '10px',
                  marginBottom: '40px',
                }}
              >
                Creating roadmaps, guides, and educational content to help
                students choose a career path and navigate their learning
                journey.
              </Typography>
            </Box>

            <JobSearchBar />

            {/* Grid Layout with Sidebar and Job Cards */}
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Grid container spacing={3} sx={{ width: '100%', margin: 0 }}>
                {/* Job Cards Section - Left */}
                <Grid item xs={12} sm={8} md={9} sx={{ paddingRight: 0 }}>
                  {/* Reduced padding */}
                  {loading
                    ? Array.from(new Array(3)).map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Card sx={{ borderRadius: 6 }}>
                            <CardContent>
                              <Skeleton
                                variant="rectangular"
                                width={40}
                                height={40}
                                sx={{ marginRight: 2 }}
                              />
                              <Skeleton
                                variant="text"
                                width="70%"
                                height={30}
                              />
                              <Skeleton
                                variant="text"
                                width="50%"
                                height={20}
                                sx={{ marginBottom: 2 }}
                              />
                              <Skeleton
                                variant="text"
                                width="80%"
                                height={20}
                              />
                              <Skeleton
                                variant="text"
                                width="60%"
                                height={20}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    : jobData && (
                        <Grid container spacing={1} sx={{ width: '100%' }}>
                          {/* Adjusted spacing here */}
                          <JobCards jobData={jobData} />
                        </Grid>
                      )}
                </Grid>

                {/* Applied Jobs Section - Right Sidebar */}
                <Grid item xs={12} sm={4} md={3} sx={{ paddingLeft: 2 }}>
                  {/* Adjusted padding for balance */}
                  <AppliedJob
                    jobData={jobData}
                    user={user}
                    userpref={userpref}
                    DateToIST={DateToIST}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        )}
      </section>
    </Layout>
  );
};

export default Jobs;
