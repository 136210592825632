import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  ListItemIcon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useRouter } from 'next/router';

const JobSearchBar = () => {
  const [searchTerms, setSearchTerms] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const router = useRouter();

  const handleAddChip = () => {
    if (inputValue.trim() && !searchTerms.includes(inputValue)) {
      setSearchTerms([...searchTerms, inputValue.trim()]);
      setInputValue(''); // Clear the input field
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setSearchTerms((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  // Sample data for states and cities
  const locations = {
    Maharashtra: ['Mumbai', 'Pune', 'Nagpur'],
    Karnataka: ['Bengaluru', 'Mysuru', 'Mangaluru'],
    TamilNadu: ['Chennai', 'Coimbatore', 'Madurai'],
    Gujarat: ['Ahmedabad', 'Surat', 'Vadodara'],
    Rajasthan: ['Jaipur', 'Udaipur', 'Jodhpur'],
    Delhi: ['New Delhi', 'Delhi'],
    // Add more states and their popular cities as needed
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  return (
    <Paper
      elevation={0} // Removed shadow
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1000,
        mx: 'auto',
        p: 2, // Increased padding for better touch targets
        borderRadius: 10,
        backgroundColor: '#fff',
        marginBottom: '40px',
        flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens
      }}
    >
      {/* Back Button */}
      <IconButton onClick={() => router.back()} sx={{ ml: 1, mb: { xs: 1, sm: 0 } }}>
        <ArrowBackIosNewIcon />
      </IconButton>

      {/* Search Input */}
      <InputBase
        placeholder="Search for job titles..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleAddChip();
          }
        }}
        sx={{
          flexGrow: 1,
          borderRadius: '4px',
          bgcolor: '#f1f1f1',
          p: '4px 8px',
          ml: 1,
          width: { xs: '100%', sm: 'auto' }, // Full width on small screens
        }}
      />

      <IconButton onClick={handleAddChip}>
        <SearchIcon sx={{ color: 'blue', ml: 1 }} />
      </IconButton>

      {/* Chips for search terms */}
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 2, flexWrap: 'wrap', mb: { xs: 1, sm: 0 } }}>
        {searchTerms.map((term) => (
          <Chip
            key={term}
            label={term}
            color="primary"
            onDelete={() => handleDeleteChip(term)}
            sx={{ mr: 1, mb: 1 }} // Added margin at the bottom for better spacing
          />
        ))}
      </Box>

      {/* Location Dropdown */}
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 2, width: { xs: '100%', sm: 'auto' } }}>
        <LocationOnIcon sx={{ color: 'gray', mr: 1 }} />
        <FormControl sx={{ minWidth: { xs: '100%', sm: 200 } }}>
          <Select
            value={selectedLocation}
            onChange={handleLocationChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => selected || 'Select Location'}
            sx={{
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="" disabled>
              Select Location
            </MenuItem>
            {Object.keys(locations).map((state) => (
              <React.Fragment key={state}>
                <MenuItem disabled>{state}</MenuItem>
                {locations[state].map((city) => (
                  <MenuItem key={city} value={`${city}, ${state}`}>
                    <ListItemIcon>
                      <LocationOnIcon fontSize="small" />
                    </ListItemIcon>
                    {city}
                  </MenuItem>
                ))}
              </React.Fragment>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Job Type */}
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 2, width: { xs: '100%', sm: 'auto' }, mb: { xs: 1, sm: 0 } }}>
        <WorkIcon sx={{ color: 'gray', mr: 1 }} />
        <Typography color="textSecondary" variant="body2">Job Type</Typography>
      </Box>

      {/* Find Job Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{
          ml: 'auto',
          backgroundColor: '#007bff',
          color: '#fff',
          textTransform: 'none',
          fontWeight: 'bold',
          borderRadius: '8px',
          mt: { xs: 1, sm: 0 }, // Margin top for mobile
          width: { xs: '100%', sm: 'auto' }, // Full width on small screens
        }}
      >
        Find Job
      </Button>
    </Paper>
  );
};

export default JobSearchBar;
