import React from 'react';
import { Card, CardContent, Typography, Box, IconButton } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import CloseIcon from '@mui/icons-material/Close'; // For the close "X" button on the cards

const AppliedJob = ({ jobData, user, userpref, DateToIST }) => {
  const router = useRouter();

  return (
    <section
      className="right-sidebar"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

        right: 0,
        top: 100, // Adjust according to your layout
        width: '250px', // Set fixed width
        height: '100vh',
        padding: '10px',
        overflowY: 'hide',
        // marginLeft: '20px',
        backgroundColor: '#fff',
        // boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          paddingBottom: '10px',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
        }}
      >
        Applied Jobs
      </Typography>

      {Boolean(user?.emailAddresses[0]?.emailAddress) &&
        jobData
          .filter((data) => {
            if (userpref?.jobs?.length) {
              for (let pref of userpref.jobs) {
                if (pref.id === data.id) {
                  return true;
                }
              }
            }
            return false;
          })
          .map((jobpost) => (
            <section
              key={jobpost.id}
              className="job-card"
              style={{ marginBottom: '20px', width: '100%' }}
            >
              <Card
                sx={{
                  borderRadius: '10px',
                  minHeight: '120px',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                  padding: '10px',
                  position: 'relative', // To position the close button inside the card
                }}
              >
                <CardContent>
                  {/* Close Icon Button */}
                  <IconButton
                    aria-label="close"
                    sx={{ position: 'absolute', top: '10px', right: '10px' }}
                    onClick={() => console.log('Close Job Card')} // Add actual delete functionality here
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* Company Logo and Name */}
                  <Box display="flex" alignItems="center" mb={2}>
                    <Image
                      src={jobpost.company_icon}
                      width={40}
                      height={40}
                      alt={jobpost.company_name}
                      style={{
                        marginRight: '10px',
                        borderRadius: '50%',
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        component="div"
                        fontWeight="bold"
                      >
                        {jobpost.job_domain}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: '#596574' }}>
                        {jobpost.company_name}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Job Details */}
                  <Typography variant="body2" color="text.secondary" mb={1}>
                    {jobpost.location}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    fontWeight="bold"
                    sx={{ color: '#006DDD' }}
                  >
                    {jobpost.salary_range} / month
                  </Typography>
                </CardContent>
              </Card>
            </section>
          ))}
    </section>
  );
};

export default AppliedJob;
